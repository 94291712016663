/* .rbc-toolbar-label{
  text-align: end !important;
  order: 2;
  color: #333333;
}

.rbc-btn-group{
  order: 1;
} */

.rbc-toolbar button {
  color: #ffffff;
  background: #0082B9;
  border: 1px solid #fff;
  border-radius: 0.375rem;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #FF7300;
  border-color: #ffffff;
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #ffffff;
  background-color: #FF7300;
  border-color: #ffffff;
}
.rbc-toolbar button:focus {
  color: #ffffff;
  background-color: #0082B9;
  border-color: #ffffff;
}
.rbc-toolbar button:hover {
  color: #ffffff;
  background-color: #FF7300;
  border-color: #ffffff;
}

.big-calendar-rtl .rbc-btn-group button + button {
  margin-right: 0px;
  margin-left: -1px;
}
.big-calendar-rtl .rbc-btn-group button + button {
  margin-right: -1px;
  margin-left: 0px;
}

.big-calendar-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.big-calendar-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.big-calendar-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.big-calendar-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* ----------------------------------------------------------- */
.big-calendar-ltr .rbc-btn-group button + button {
  margin-left: 0px;
  margin-right: -1px;
}
.big-calendar-ltr .rbc-btn-group button + button {
  margin-left: -1px;
  margin-right: 0px;
}

.big-calendar-ltr .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.big-calendar-ltr .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.big-calendar-ltr .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.big-calendar-ltr .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-row{
  min-height: 45px !important;
}

.rbc-row-segment{
  min-height: 45px !important;
}

.rbc-event, .rbc-selected{
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rbc-event-label{
  display: none !important;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;  
  min-height: fit-content !important;
  height: auto !important;
  margin-top: -10px !important;
}

/*.rbc-off-range {*/
/*  color: transparent;*/
/*  pointer-events: none;*/
/*}*/