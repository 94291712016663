.auth-rtl .iti--allow-dropdown input[type=tel]
{
    @apply h-10 w-[270px] sm:w-[300px] pr-11 !important
}

.auth-rtl .iti--separate-dial-code .iti__selected-flag {
    border-radius: 0 10px 10px 0 !important;
}

.auth-rtl .iti__selected-dial-code {
    margin-right: 4px;
}

.auth-rtl .iti__flag-container {
    left: auto !important;
    right: 0 !important;
}

.auth-rtl input[name='phone'] {
    padding-left: 0 !important;
    padding-right: 100px !important;
}

.auth-rtl input {
    direction: rtl !important;
    text-align: start;
}

.auth-rtl .iti__arrow {
    @apply mr-[6px] ml-0;
}

/* ---------------------------------------------------- */

.auth-ltr .iti__arrow {
    @apply ml-[6px] mr-0;
}

.auth-ltr .iti--allow-dropdown input[type=tel]
{
    @apply h-10 w-[270px] sm:w-[300px] 
}

.auth-ltr .iti--separate-dial-code .iti__selected-flag {
    border-radius: 10px 0 0 10px!important;
} 

