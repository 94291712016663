.rbc-toolbar button {
  color: #ffffff;
  background: #309DFF;
  border: 1px solid #fff;
  border-radius: 0.375rem;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #F16C00;
  border-color: #ffffff;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: #ffffff;
  background-color: #F16C00;
  border-color: #ffffff;
}

.rbc-toolbar button:focus {
  color: #ffffff;
  background-color: #309DFF;
  border-color: #ffffff;
}

.rbc-toolbar button:hover {
  color: #ffffff;
  background-color: #F16C00;
  border-color: #ffffff;
}

.rbc-row {
  min-height: 45px !important;
}

.rbc-row-segment {
  min-height: 45px !important;
}

.rbc-event,
.rbc-selected {
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  min-height: fit-content !important;
  height: auto !important;
  margin-top: -10px !important;
}

.rbc-month-row {
  display: flex !important;
  position: relative !important;
  flex-direction: column !important;
  flex: 1 0 !important;
  overflow: hidden !important;
  min-height: 90px !important;
}

.streak-calendar-right > .rbc-toolbar > .rbc-toolbar-label {
  text-align: left;
  color: #F16C00;
  font-weight: bold;
}

.streak-calendar-left > .rbc-toolbar > .rbc-toolbar-label {
  text-align: right;
  color: #F16C00;
  font-weight: bold;
}

.hide-rbc-button-group > .rbc-toolbar {
  display: none;
}