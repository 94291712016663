.bottom-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 10px 0 10px;
    border-color: #333333 transparent transparent transparent;
    border-radius: 2px;
}

/* used for card information dropdown */
select {
    -webkit-appearance: none;
}

.tooltip {
    visibility: hidden;
    position: absolute;
}

.has-tooltip:hover .tooltip {
    visibility: visible;
    z-index: 100;
}

.has-tooltip:focus .tooltip {
    visibility: visible;
    z-index: 100;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* ********************** */